/**
 * Load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */
import axios from 'axios';
import router from './router';
import config from '../config';

import useSentry from './composables/useSentry';
import { useUserStore } from './stores/user';

export default {
  install: (app) => {
    const a = axios.create({
      baseURL: import.meta.env.VITE_BASE_URL,
      https: false,
      proxy: {
        protocol: 'https',
        host: '127.0.0.1',
        port: 9000,
      },
      withCredentials: true,
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Content-type': 'application/json',
      },
    });

    a.interceptors.response.use(
      (response) => response,
      (error) => {
        const ignoredUrls = [
          config.endpoints.reports.image,
        ];

        if (!ignoredUrls.includes(error.config.url)) {
          let path = '';
          const userStore = useUserStore();
          switch (error.response.status) {
            case 401:
              userStore.handleUnauthorised(true);
              break;
            case 404: path = '/404'; break;
            case 403: path = '/403'; break;
            default: break;
          }
          if (path !== '') {
            router.push(path);
          }

          useSentry(error, true);
        }

        return Promise.reject(error);
      },
    );

    // eslint-disable-next-line no-param-reassign
    app.config.globalProperties.$axios = a;
    // eslint-disable-next-line no-param-reassign
    app.config.globalProperties.$config = config;
  },
};
