import { defineStore } from 'pinia';
import { useStorage } from '@vueuse/core';

import { useUserStore } from './user';
import { useOrganisationStore } from './organisation';

// eslint-disable-next-line import/prefer-default-export
export const useApplicationStore = defineStore('application', {
  state: () => ({
    toasts: useStorage('toasts', []),
    enduringToast: useStorage('enduringToast', []),
    appLoadComplete: false,
    appLoading: false,
  }),
  actions: {
    async appLoad() {
      this.appLoading = true;
      const organisationStore = useOrganisationStore();
      const userStore = useUserStore();
      await userStore.fetchUser();
      if (userStore.loggedIn) {
        await userStore.fetchPermissions();
        this.appLoadComplete = true;
        this.appLoading = false;
        this.clearAllToast();
        organisationStore.getTierLimits();
        userStore.getNotificationCount();
        userStore.getBookmarks();
      }
    },
    clearToast() {
      this.toasts.shift();
    },
    clearAllToast() {
      this.toasts = [];
    },
    createToast(toast) {
      this.toasts.push(toast);
      setTimeout(() => {
        const index = this.toasts.indexOf(toast);
        this.removeToast(index);
      }, 4500);
    },
    removeToast(index) {
      this.toasts.splice(index, 1);
      setTimeout(() => this.clearToast, 4500);
    },
    setEnduringToast(toast) {
      this.enduringToast = toast.content;
    },
  },
});
