<template>
  <div>
    <div
      id="site-navigation"
      class="site-navigation flex h-screen shrink-0 grow-0 items-center justify-center bg-sl-black-600 shadow-lg transition-all duration-500 ease-in-out"
      :class="[collapseNavigation ? 'w-12' : 'w-52']"
    >
      <div
        class="flex size-full flex-col items-center text-white"
      >
        <span
          v-if="$userStore.user.portal_id === null"
          class="flex h-14 w-full items-center bg-sl-black-800 px-4 py-6"
        >
          <RouterLink
            :to="{ name: 'home' }"
            class="flex items-center justify-center"
          >
            <img
              class="h-10"
              src="../../assets/images/searchlight_logo_light.svg"
            >
          </RouterLink>
        </span>
        <span
          v-if="$userStore.user.portal_id !== null"
          v-click-away="clickAway"
          data-testid="app-switcher-toggle"
          class="flex h-14 w-full cursor-pointer items-center bg-sl-black-800 px-4 py-6 text-white hover:bg-sl-black-400 hover:text-sl-orange-700"
          @click="toggleAppSwitcher()"
        >
          <font-awesome-icon
            class="size-6.25 text-sl-orange-700"
            :icon="['fas', 'grid-round']"
          />
          <img
            class="h-6.25 pl-3.75 pr-2.5"
            src="../../assets/images/Logo_DarkIQ_Text_Black.svg"
          >
          <font-awesome-icon
            class="w-2 text-inherit"
            :icon="['fas', 'caret-down']"
          />
          <SlAppSwitcher
            v-if="showAppSwitcher"
            class="absolute left-1.25 top-15.5 z-50"
            active-application="darkiq"
            :applications="$userStore.applications"
            :username="$userStore.user.profile.forename"
            :up-sell="$userStore.organisationIsParent"
            :portal="$userStore.idpUrl"
            logout-url="/api/saml/searchlight/logout"
            @click.stop
          />
        </span>
        <div class="flex size-full flex-col justify-between">
          <div class="w-full">
            <div
              v-for="item in navigation"
              :key="item.link"
              class="flex w-full flex-col"
              :class="{
                'border-l-4 border-sl-orange-700 bg-sl-black-800': isParentRoute(item.link)
              }"
            >
              <RouterLink
                v-if="item.visible || item.visible === undefined"
                :to="{ name: item.link }"
                :data-testid="getTestId(item.title, '-menu-item')"
                class="relative h-12 w-full"
                :class="{'h-full': isParentOrCurrentRoute(item.link)}"
              >
                <div
                  class="flex h-12 items-center px-4 hover:bg-sl-black-400"
                  :class="[navLinkStyling(item.link)]"
                >
                  <font-awesome-icon
                    :icon="[isParentOrCurrentRoute(item.link) ? 'fas': 'fal', item.icon]"
                    :fixed-width="true"
                    class="mr-3"
                  />
                  <span
                    class="main-body ml-2 whitespace-nowrap !text-white"
                    :class="{'small-title' : isParentOrCurrentRoute(item.link)}"
                  >
                    {{ item.title }}
                    <SitePing
                      v-if="item.link === 'notifications' && $userStore.getNotifications > 0"
                      styles="absolute left-0 top-0 mt-2 ml-7"
                    />
                  </span>
                </div>
              </RouterLink>
              <div
                v-if="showExpandedSubGroup(item)"
                :class="[showExpandedSubGroup(item) ? 'mb-3' : 'invisible mb-0 max-h-0']"
                class="ml-4 block font-normal transition-all duration-200 ease-in-out"
              >
                <div
                  v-for="subItem in item.subNavigation"
                  :key="subItem.value"
                >
                  <RouterLink
                    v-if="subItem.visible"
                    :key="subItem.link"
                    :to="subItem.disabled ? {} : {
                      name: subItem.link,
                      params: {
                        id: $route.params.id
                      }
                    }"
                    :data-testid="getTestId(subItem.title, '-sub-menu-item')"
                    class="w-full transition-all duration-100 ease-linear"
                    :class="[
                      showExpandedSubGroup(item) ? 'mb-3' : 'invisible mb-0 h-0',
                      subItem.disabled ? 'disabled' : ''
                    ]"
                  >
                    <div
                      class="relative ml-4 mr-2 flex h-10 items-center rounded px-2 transition-all duration-100 ease-linear hover:bg-sl-black-400"
                      :class="[{
                        'disabled cursor-not-allowed opacity-60': subItem.disabled,
                        'text-sl-orange-700': isParentOrCurrentRoute(subItem.link)
                      }]"
                    >
                      <font-awesome-icon
                        :icon="[
                          isParentOrCurrentRoute(subItem.link) ?
                            'fas' :
                            'fal',
                          subItem.icon
                        ]"
                        :fixed-width="true"
                        class="mr-3"
                        :class="[{
                          'disabled cursor-not-allowed opacity-60': subItem.disabled
                        }]"
                      />
                      <span
                        :class="{
                          'small-title !text-sl-orange-700': isParentOrCurrentRoute(subItem.link),
                          'disabled': subItem.disabled
                        }"
                        class="main-body ml-2 !text-white"
                      >
                        {{ subItem.title }}
                      </span>
                      <SitePing
                        v-if="!subItem.disabled &&
                          subItem.link === 'companies-id-attributes' &&
                          $companyStore.company?.discovered_attributes_count
                        "
                        styles="absolute left-0 top-0 mt-2 ml-6"
                        size="xs"
                      />
                    </div>
                  </RouterLink>
                </div>
              </div>
            </div>
          </div>
          <div class="flex w-full flex-col overflow-hidden">
            <!-- <div
              class="flex justify-center items-center px-4 my-4 w-full transition-all duration-500 ease-in-out cursor-pointer"
              :class="{'pl-20' : collapseNavigation}"
              @click.prevent="toggleNavigation"
            >
              <font-awesome-icon
                :fixed-width="true"
                :icon="['fas', collapseNavigation ?
                  'chevron-double-right' :
                  'chevron-double-left']"
              />
              <span
                :class="{'invisible' : collapseNavigation}"
                class="ml-2 whitespace-nowrap transition-all duration-100 ease-in-out"
              >
                {{ $t('navigation.collapse_navigation') }}
              </span>
            </div> -->
            <div
              v-if="!$userStore.user.portal_id"
              class="flex h-10 w-full cursor-pointer items-center justify-center bg-sl-black-800 pr-1 !font-bold !text-sl-orange-700 hover:bg-sl-black-400"
              :class="{'pl-16' : collapseNavigation}"
              @click.prevent="logout"
            >
              <font-awesome-icon
                :icon="['fas', 'sign-out-alt']"
                :fixed-width="true"
              />
              <span
                :class="{'invisible' : collapseNavigation}"
                class="main-body ml-2 whitespace-nowrap !font-bold !text-sl-orange-700"
              >
                {{ $t('navigation.log_out') }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SlAppSwitcher } from '@slcyber/component-library';
import HelperFunctions from '../../mixins/HelperFunctions';
import SitePing from '../SitePing.vue';

export default {
  name: 'SiteNavigation',
  components: {
    SitePing,
    SlAppSwitcher,
  },
  mixins: [HelperFunctions],
  props: {
    collapseNavigation: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['toggle-navigation'],
  data() {
    return {
      loading: true,
      navigation: [],
      loadingPermissions: true,
      showAppSwitcher: false,
    };
  },
  watch: {
    '$route.name': function handler(newName, oldName) {
      if (!oldName.startsWith('companies-id')) {
        this.navigation[2].subNavigation.forEach((item, index) => {
          this.navigation[2].subNavigation[index].disabled = true;
        });
      }

      if (newName.startsWith('companies-id')) {
        this.checkCompany();
      }
    },
    '$userStore.permissions': function handler(newValue) {
      if (newValue.length) {
        this.setNavigation();
        this.checkCompany();
      }
    },
  },
  mounted() {
    if (this.$userStore.permissions.length > 0) {
      this.setNavigation();
      this.checkCompany();
    }
  },
  methods: {
    toggleAppSwitcher() {
      this.showAppSwitcher = !this.showAppSwitcher;
    },
    clickAway() {
      this.showAppSwitcher = false;
    },
    setNavigation() {
      this.navigation = [
        {
          icon: 'user',
          link: 'administration',
          visible: this.$userStore.user.site_role === 'Administrator',
          title: this.$t('navigation.administration.administration'),
          subGroup: 'administration',
          subNavigation: [
            {
              icon: 'users',
              link: 'administration-users',
              title: this.$t('navigation.administration.users'),
              visible: true,
            },
            {
              icon: 'building',
              link: 'administration-organisations',
              title: this.$t('navigation.administration.organisations'),
              visible: true,
            },
            {
              icon: 'layer-group',
              link: 'administration-tiers',
              title: this.$t('navigation.administration.tiers'),
              visible: true,
            },
            {
              icon: 'newspaper',
              link: 'administration-news',
              title: this.$t('navigation.administration.news'),
              visible: true,
            },
            {
              icon: 'pound-sign',
              link: 'administration-finance',
              title: this.$t('navigation.administration.finance'),
              visible: true,
            },
          ],
        },
        {
          icon: 'home-lg',
          link: 'home',
          title: this.$t('navigation.dashboard'),
        },
        {
          icon: 'laptop-house',
          link: 'companies',
          title: this.$t('navigation.companies'),
          subGroup: 'companies-id',
          subNavigation: [
            {
              icon: 'tachometer-alt-fastest',
              link: 'companies-id-dashboard',
              title: this.$t('navigation.company.dashboard'),
              visible: true,
              disabled: true,
            },
            {
              icon: 'list-ol',
              link: 'companies-id-attributes',
              title: this.$t('navigation.company.attributes'),
              visible: this.$can('company-attributes'),
              disabled: true,
            },
            {
              icon: 'sensor-alert',
              link: 'companies-id-actions',
              title: this.$t('navigation.company.actions'),
              visible: true,
              disabled: true,
            },
            {
              icon: 'search',
              link: 'companies-id-explore',
              title: this.$t('navigation.company.explore'),
              visible: true,
              disabled: true,
            },
            {
              icon: 'traffic-cone',
              link: 'companies-id-traffic',
              title: this.$t('navigation.company.traffic'),
              visible: true,
              disabled: true,
            },
            {
              icon: 'file',
              link: 'companies-id-report',
              title: this.$t('navigation.company.report'),
              visible: this.$can('generate-companies-report'),
              disabled: true,
            },
            {
              icon: 'cog',
              link: 'companies-id-settings',
              title: this.$t('navigation.company.settings'),
              visible: true,
              disabled: true,
            },
            {
              icon: 'history',
              link: 'companies-id-history',
              title: this.$t('navigation.company.history'),
              visible: true,
              disabled: true,
            },
          ],
        },
        {
          icon: 'copy',
          link: 'risk-reports',
          visible: this.$can('use-risk-reports'),
          title: this.$t('navigation.risk_reports'),
        },
        {
          icon: 'search',
          link: 'search',
          visible: this.$can('use-basic-darknet-search'),
          title: this.$t('navigation.darknet_search'),
        },
        {
          icon: 'bookmark',
          link: 'bookmarks',
          visible: this.$can('use-bookmarks'),
          title: this.$t('navigation.bookmarks'),
        },
        {
          icon: 'newspaper',
          link: 'news',
          title: this.$t('navigation.news'),
        },
        {
          icon: 'bell',
          link: 'notifications',
          title: this.$t('navigation.notifications'),
        },
        {
          icon: 'user',
          link: 'account',
          title: this.$t('navigation.account'),
        },
        {
          icon: 'comment-lines',
          link: 'support',
          title: this.$t('navigation.support'),
        },
      ];

      this.loadingPermissions = false;
    },
    async logout() {
      this.$userStore.logout();
    },
    toggleNavigation() {
      this.$emit('toggle-navigation');
    },
    isCurrentRoute(name) {
      return this.$route.name === name;
    },
    isParentRoute(name) {
      return this.$route.name.startsWith(name);
    },
    isParentOrCurrentRoute(name) {
      return this.isCurrentRoute(name) || this.isParentRoute(name);
    },
    isSiblingRoute(name) {
      // Get the parent of the current route if it has one
      const parent = this.$router.options.routes?.find((route) => route.name === this.$route.name ||
            route.children?.find((child) => child.name === this.$route.name));

      // If the parent exists and has children check if the current
      // route is a sibling of the specified route.
      return parent?.children?.map((child) => child.name).includes(name) ?? false;
    },
    showExpandedSubGroup(item) {
      return !this.collapseNavigation &&
        item.subNavigation &&
        this.isParentOrCurrentRoute(item.subGroup);
    },
    navLinkStyling(name) {
      if (this.isCurrentRoute(name) && this.collapseNavigation) {
        return 'pl-3';
      }
      if (this.isCurrentRoute(name) && !this.collapseNavigation) {
        return 'px-3';
      }
      if (!this.collapseNavigation) {
        return 'px-4';
      }
      return '';
    },
    async checkCompany() {
      if (this.$route.name.includes('companies-id')) {
        await this.$companyStore.getCompany(
          this.$route.params.id,
          { params: { load_discovered_counts: 1 } },
        );

        this.checkLimitedAccess();
      } else {
        this.navigation[2].subNavigation.forEach((item, index) => {
          this.navigation[2].subNavigation[index].disabled = false;
        });
        this.loading = false;
      }
    },
    checkLimitedAccess() {
      this.navigation[2].subNavigation.forEach((item, index) => {
        if (this.$companyStore.company.limited_access &&
            [
              'companies-id-dashboard',
              'companies-id-attributes',
              'companies-id-traffic',
            ].includes(item.link)
        ) {
          this.navigation[2].subNavigation[index].disabled = true;
        } else {
          this.navigation[2].subNavigation[index].disabled = false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
#collapsed-nav {
  p {
    display: hidden;
  }
}
</style>
