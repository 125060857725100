import { defineStore } from 'pinia';
import { useStorage } from '@vueuse/core';
import config from '../../config';

// eslint-disable-next-line import/prefer-default-export
export const useOrganisationStore = defineStore('organisation', {
  state: () => ({
    limits: useStorage('limits', []),
  }),
  actions: {
    getTierLimits() {
      this.$axios.get(`${config.endpoints.tier}/limits`).then((response) => {
        this.setTierLimits(response);
      });
    },
    setTierLimits(limits) {
      this.limits = limits.data.source;
    },
    clearTierLimits() {
      this.limits = {};
    },
  },
});
