import { defineStore } from 'pinia';
import { useStorage } from '@vueuse/core';

import config from '../../config';

// eslint-disable-next-line import/prefer-default-export
export const useCompanyStore = defineStore('company', {
  state: () => ({
    company: useStorage('company', null),
    companies: useStorage('companies', null),
    loadingCompany: false,
  }),
  actions: {
    async getCompany(id, params = {}) {
      if (
        this.company === null ||
        parseInt(id, 10) !== this.company.id ||
        typeof this.company === 'string'
      ) {
        await this.fetchCompany(id, params);
      }
      return this.company;
    },
    async fetchCompany(id, params = {}) {
      if (this.loadingCompany) {
        return;
      }
      this.loadingCompany = true;

      await this.$axios.get(`${config.endpoints.companies.index}/${id}`, params)
        .then((response) => {
          this.setCompany(response.data.source);
        }).finally(() => {
          this.loadingCompany = false;
        });
    },
    async updateCompany(params) {
      await this.$axios.patch(`${config.endpoints.companies.index}/${this.company.id}`, params)
        .then((response) => {
          this.setCompany(response.data.company);
        });

      return this.company;
    },
    async deleteCompany(id) {
      await this.$axios.delete(`${config.endpoints.companies.index}/${id}`)
        .then((response) => {
          this.$toast(response.data.message, 'success');
        })
        .catch((error) => {
          this.$Sentry.captureException(error);
          this.$toast(this.$t('error.toast'), 'warning');
        });
    },
    async createCompany(params = {}) {
      await this.$axios.post(config.endpoints.companies.index, params)
        .then((response) => {
          this.setCompany(response.data.source);
        })
        .catch((error) => {
          this.$Sentry.captureException(error);
          this.$toast(this.$t('error.toast'), 'warning');
        });
    },
    async getCompanies(params = {}) {
      await this.$axios.get(config.endpoints.companies.index, params)
        .then((response) => {
          this.companies = response.data.source;
        }).catch((error) => {
          this.$Sentry.captureException(error);
          this.$toast(error.response.data.message, 'warning');
        });

      return this.companies;
    },
    setCompany(company) {
      this.company = company;
    },
    clearCompany() {
      this.setCompany(null);
    },
  },
  getters: {
    companyScanInProgress() {
      if (this.company) {
        return ['discovery', 'threat_queued', 'threat_scan']
          .includes(this.company.stage);
      }
      return false;
    },
  },
});
